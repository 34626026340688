import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from '../assets/logo.png'; // Ajuste o caminho conforme necessário
import '../assets/IconGrid.css'; // Ajuste o caminho conforme necessário

const ICONS_PER_PAGE = 55;

const IconGrid = () => {
  const [icons, setIcons] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchIcons = async () => {
      try {
        const response = await axios.get('https://s3.us-west-1.wasabisys.com/svg-icons-nerdgpt', {
          params: {
            delimiter: '/',
            prefix: 'SVG/'
          }
        });
        // Processar a resposta XML para extrair os nomes dos arquivos SVG
        const parser = new DOMParser();
        const xml = parser.parseFromString(response.data, 'application/xml');
        const keys = Array.from(xml.getElementsByTagName('Key')).map(key => key.textContent);
        const svgIcons = keys.filter(key => key.endsWith('.svg')).map(key => ({
          name: key.replace('SVG/', '').replace('.svg', ''),
          url: `https://s3.us-west-1.wasabisys.com/svg-icons-nerdgpt/${key}`
        }));
        setIcons(svgIcons);
      } catch (error) {
        console.error('Erro ao buscar os ícones:', error);
      }
    };

    fetchIcons();
  }, []);

  // Atualiza o estado da página para 1 sempre que o termo de busca mudar
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  // Filtrar ícones com base no termo de busca
  const filteredIcons = icons.filter(icon =>
    icon.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Paginação
  const indexOfLastIcon = currentPage * ICONS_PER_PAGE;
  const indexOfFirstIcon = indexOfLastIcon - ICONS_PER_PAGE;
  const currentIcons = filteredIcons.slice(indexOfFirstIcon, indexOfLastIcon);

  const totalPages = Math.ceil(filteredIcons.length / ICONS_PER_PAGE);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const downloadIcon = (url, name) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}.svg`;
        a.click();
        URL.revokeObjectURL(url);
      });
  };

  /**const copyLink = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      alert('Link copiado para a área de transferência');
    }).catch(err => {
      console.error('Erro ao copiar o link:', err);
    });
  };**/

  const copyLink = (url, name) => {
    const imgTag = `<img src="${url}" alt="${name} Icon" />`;
    navigator.clipboard.writeText(imgTag).then(() => {
      alert('Código HTML copiado para a área de transferência');
    }).catch(err => {
      console.error('Erro ao copiar o código HTML:', err);
    });
  };
  

  return (
    <div className="IconGrid">
      <header>
        <img src={logo} alt="Logo" className="logo" />
        <input
          type="text"
          placeholder="Buscar ícones..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </header>
      <div className="pagination">
        {[...Array(totalPages).keys()].map(page => (
          <button
            key={page + 1}
            className={`page-button ${currentPage === page + 1 ? 'active' : ''}`}
            onClick={() => handlePageChange(page + 1)}
          >
            {page + 1}
          </button>
          
        ))}
      </div>
      <main id="icon-grid">
        {currentIcons.map(icon => (
          <div key={icon.name} className="icon-item">
            <img src={icon.url} alt={icon.name} />
            <p>{icon.name}</p>
            <button onClick={() => copyLink(icon.url, icon.name)}>Copiar Link</button>
          </div>
        ))}
      </main>
      <footer>
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Anterior
        </button>
        <span>Página {currentPage} de {totalPages}</span>
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Próxima
        </button>
      </footer>
    </div>
  );
};

export default IconGrid;
